import React from "react";

const CookiesPolicy = () => {
  return (
    <>
      {/* <!-- === tos section start === --> */}
      <section className="tos">
        <div className="container">
          <div className="tos-card shadow">
            <h3 className="agreement-title heading-3">Agreement</h3>
            <h1 className="main-title">Cookie Policy</h1>
            <ol className="tos-content list-style-none">
              <li>
                <p className="text-preline">
                  This Cookie Policy explains how Cluvii (“we”, “us” or “our”)
                  uses cookies and similar technologies in connection with the
                  www.cluvii.com website.
                </p>
              </li>
              <li className="text-preline">
                <h4 className="tos-title"> What are cookies? </h4>
                <p>
                  Cookies are small text files placed on your computer by
                  websites and sometimes by emails. They provide useful
                  information to organizations, which helps to make your visits
                  to their websites more effective and efficient. We use cookies
                  to ensure that we are able to understand how you use our
                  websites and to ensure that we can make improvements to the
                  websites. Cookies do not contain any personal or confidential
                  information about you.
                </p>
              </li>

              <li className="text-preline">
                <h4 className="tos-title"> How we use cookies </h4>
                <p>
                  We use cookies to ensure that you get the best from our
                  website. The first time that you visit our website you will be
                  asked to consent to our use of cookies and we suggest that you
                  agree to allow the cookies to be active on your device whilst
                  you visit and browse our website to ensure that you experience
                  our website fully. The types of cookies that we may use
                  include:
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>Session cookies</strong>
                    </p>
                    <p>
                      Session cookies last only for the duration of your visit
                      and are deleted when you close your browser. These
                      facilitate various tasks such as allowing a website to
                      identify that a user of a particular device is navigating
                      from page to page, supporting website security or basic
                      functionality.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Persistent cookies</strong>
                    </p>
                    <p>
                      Persistent cookies last after you have closed your
                      browser, and allow a website to remember your actions and
                      preferences. Sometimes persistent cookies are used by
                      websites to provide targeted advertising based upon the
                      browsing history of the device. We use persistent cookies
                      to allow us to analyze users visit to our site. These
                      cookies help us to understand how customers arrive at and
                      use our site so we can improve the overall service.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Strictly necessary cookies</strong>
                    </p>
                    <p>
                      These cookies are essential in order to enable you to move
                      around the website and use its features, and ensuring the
                      security of your experience. Without these cookies
                      services you have asked for, such as applying for products
                      and managing your accounts, cannot be provided. These
                      cookies don’t gather information about you for the
                      purposes of marketing.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Performance cookies</strong>
                    </p>
                    <p>
                      These cookies collect information about how visitors use a
                      web site, for instance which pages visitors go to most
                      often, and if they get error messages from web pages. All
                      information these cookies collect is only used to improve
                      how a website works, the user experience and to optimize
                      our advertising. By using our websites you agree that we
                      can place these types of cookies on your device, however
                      you can block these cookies using your browser settings.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> Functionality cookies </strong>
                    </p>
                    <p>
                      {" "}
                      These cookies allow the website to remember choices you
                      make (such as your username). The information these
                      cookies collect is anonymized (i.e. it does not contain
                      your name, address etc.) and they do not track your
                      browsing activity across other websites. By using our
                      websites you agree that we can place these types of
                      cookies on your device, however you can block these
                      cookies using your browser settings.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> Targeting cookies </strong>
                    </p>
                    <p>
                      {" "}
                      These cookies collect several pieces of information about
                      your browsing habits. [They are usually placed by third
                      party advertising networks]. They remember that you have
                      visited a website and this information is shared with
                      other organizations such as media publishers. These
                      organizations do this in order to provide you with
                      targeted adverts more relevant to you and your interests.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> Third party cookies </strong>
                    </p>
                    <p>
                      {" "}
                      Please note that third parties (including, for example,
                      advertising networks and providers of external services
                      like web traffic analysis services) may also use cookies,
                      over which we have no control. These cookies are likely to
                      be analytical/performance cookies or targeting cookies.{" "}
                    </p>
                  </li>
                </ul>
              </li>

              <li className="text-preline">
                <h4 className="tos-title"> Managing Cookies </h4>
                <p>
                  {" "}
                  You can control and/or delete cookies as you wish – for
                  details, see aboutcookies.org. You can delete all cookies that
                  are already on your computer and you can set most browsers to
                  prevent them from being placed. If you do this, however, you
                  may have to manually adjust some preferences every time you
                  visit our website or use our Platform and some services and
                  functionalities we offer may not work. To restrict or handle
                  cookies, please see the ‘Help’ section of your internet
                  browser. For any further information, please contact us
                  ---@Cluvii.com{" "}
                </p>
              </li>
            </ol>

            <footer className="tos-footer d-lg-flex text-lg-left text-center">
              <a
                target="_blank"
                href="/"
                className="btn d-block btn-lg btn-outline-blue btn-border-2px"
              >
                Download PDF
              </a>
            </footer>
          </div>
        </div>
      </section>
      {/* <!-- ===//end of tos section === --> */}
    </>
  );
};

export default CookiesPolicy;
