import React from "react";
import howItWorksImg1 from "../../assets/images/how-its-work/img-1.png";
import howItWorksImg2 from "../../assets/images/how-its-work/img-2.png";
import howItWorksImg3 from "../../assets/images/how-its-work/img-3.png";
const HowItWorks = () => {
  return (
    // <!-- ===//end of how-its-work section === -->
    <section className="how-its-work">
      <div className="container container-xl-1170">
        <h3 className="heading-2 text-blue mb-5">How it works</h3>
        <div className="row gy-lg-0 gy-4">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <div className="card-body">
                <img
                  src={howItWorksImg1}
                  alt="howItWorksImg1"
                  className="img-fluid"
                />
                <h3>Sign up</h3>
                <p>
                  Join the Cluvii network, tell us a thing or two about who you
                  are, and select your core hobbies. It shouldn’t take more than
                  two minutes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 pt-lg-5">
            <div className="card">
              <div className="card-body">
                <img
                  src={howItWorksImg2}
                  alt="howItWorksImg2"
                  className="img-fluid"
                />
                <h3>Start enjoying Cluvii</h3>
                <p>
                  Browse the feed to find trending local activities and posts.
                  Of course, if you’re happy to catch a plane to snorkel in Maui
                  or take a group walk with giant Galapagos turtles, we won’t
                  stop you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <div className="card-body">
                <img
                  src={howItWorksImg3}
                  alt="howItWorksImg3"
                  className="img-fluid"
                />
                <h3>Plan your next adventure</h3>
                <p>
                  Click on a post that catches your eye and choose to be a
                  collaborator, or create your own and wait for others to join
                  in. If you simply want to assist in a unique experience, join
                  the loop as a spectator for a small fee.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
