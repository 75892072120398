import React from "react";
import articleImg1 from "../../assets/images/article/img-1.png";
import articleImg2 from "../../assets/images/article/img-2.png";
import articleImg3 from "../../assets/images/article/img-3.png";
const Article = () => {
  return (
    // <!-- === article section start === -->
    <section className="article">
      <article>
        <div className="container container-xl-1170">
          <div className="row align-items-center">
            <div className="col-lg-5 text-lg-end text-center mb-lg-0 mb-4 pe-lg-5">
              <img src={articleImg1} alt="Article" className="img-fluid" />
            </div>
            <div className="col-lg-7">
              <h3 className="heading-3 mb-4">
                <span className="bg-blue px-2 text-white">Seize the joy</span>{" "}
                of spontaneity.
              </h3>
              <p>
                You can get really far as long as you are open to new people,
                try new things and explore new perspectives. Don’t worry if you
                haven’t got a clue about what you’d like to try next. That’s
                what Cluvii was designed for. Browse through a wide range of
                activities until you find something that draws your attention.
              </p>
            </div>
          </div>
        </div>
      </article>

      <article className="bg-light-wavy">
        <div className="container container-xl-1170">
          <div className="row align-items-center">
            <div className="col-lg-5 text-center mb-lg-0 mb-4 order-lg-2">
              <img src={articleImg2} alt="article-2" className="img-fluid" />
            </div>
            <div className="col-lg-6 offset-lg-1 order-lg-1">
              <h3 className="heading-3 mb-4">
                <span className="bg-blue px-2 text-white">No idea</span> is too
                big.
              </h3>
              <p>
                Cluvii connects people with a passion for creativity in a
                community that encourages individuality, collaboration, and
                perseverance. Got a bold idea, and you’d like others to join in
                and get the coin for your activity, skills, or craft? You can do
                that on Cluvii – and so much more.{" "}
              </p>
            </div>
          </div>
        </div>
      </article>

      <article>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 text-lg-end text-center mb-lg-0 mb-4 pe-lg-5">
              <img src={articleImg3} alt="article3" className="img-fluid" />
            </div>
            <div className="col-lg-7">
              <h3 className="heading-4 mb-4">
                <span className="bg-blue px-2 text-white">
                  Building bridges
                </span>{" "}
                between like-minded people.
              </h3>
              <p>
                Got a niche hobby that no one else seems to be into? Come to the
                Cluvii side to meet your crew. Form a rock band, organize gaming
                tournaments, go leaf peeping, hike in Viking attire, or go dog
                spotting in your local park – the only limit is your
                imagination.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Article;
