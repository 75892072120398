import Layout from "./pages/layout/Layout";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import MainPage from "./pages/mainPage/MainPage";
import TermsofService from "./pages/termsofService/TermsofService";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/cookiesPolicy/CookiesPolicy";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path={"/"} exact element={<Navigate to="/home" />} />
          <Route path={"/home"} element={<MainPage />} />
          <Route
            path={"/home/termsofService"}
            exact
            element={<TermsofService />}
          />
          <Route
            path={"/home/privacyPolicy"}
            exact
            element={<PrivacyPolicy />}
          />
          <Route
            path={"/home/cookiesPolicy"}
            exact
            element={<CookiesPolicy />}
          />
          {/* <Route path="*">
            <NotFound />
          </Route> */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
