import React from "react";
import AboutUs from "../../components/aboutUS/AboutUs";
import Article from "../../components/article/Article";
import Banner from "../../components/banner/Banner";
import HowItWorks from "../../components/howItWorks/HowItWorks";
import JoinUS from "../../components/joinUs/JoinUS";

const MainPage = () => {
  return (
    <>
      <Banner />
      <Article />
      <HowItWorks />
      <AboutUs/>
      <JoinUS/>
    </>
  );
};

export default MainPage;
