import React from "react";

const Banner = () => {
  return (
    // <!-- === banner section start === -->
    <section className="banner">
      <div className="banner-text shadow-lg">
        <h1 className="heading-1 text-uppercase text-white">
          Boldness unites.
        </h1>
        <p className="fw-semibold text-white lh-base mt-4">
          Cluvii is a sparkling social network where bold, passionate, curious
          individuals gather together to explore new interests, discover
          untapped passions, and connect with like-minded people, regardless of
          where they are in the world.
        </p>
      </div>
      <div className="join-discord shadow">
        <h3 className="heading-3 text-white mb-3">
          Join Our Discord Server Now
        </h3>
        <a
          href="https://discord.com/invite/juY9CF7w5G"
          className="btn btn-light px-4 text-nowrap"
        >
          <i className="fa-brands fa-discord"></i>
          cluvii
        </a>
      </div>
    </section>
    // <!-- ===//end of banner section === -->
  );
};

export default Banner;
