import React from "react";

const JoinUS = () => {
  return (
    // <!-- === ready-to section start === -->
    <section className="ready-to">
      <div className="container">
        <h3 className="heading-2 text-blue mb-5">
          Are you ready to take a plunge ?
        </h3>
        <div className="join-discord shadow">
          <h3 className="heading-3 text-white mb-3">
            Join Our Discord Server Now
          </h3>
          <a
            href="https://discord.com/invite/juY9CF7w5G"
            className="btn btn-light px-4 text-nowrap"
          >
            <i className="fa-brands fa-discord"></i>
            cluvii
          </a>
        </div>
      </div>
    </section>
  );
};

export default JoinUS;
