import React from "react";
import aboutImg from "../../assets/images/article/about.png";

const AboutUs = () => {
  return (
    // <!-- === about-us section start === -->
    <section className="about-us">
      <div className="container">
        <article>
          <div className="container container-xl-1170">
            <div className="row align-items-center">
              <div className="col-lg-6 text-center mb-lg-0 mb-4">
                <img src={aboutImg} alt="aboutImg" className="img-fluid" />
              </div>
              <div className="col-lg-6">
                <h3 className="heading-2 mb-4 text-white">About Us</h3>
                <p className="mb-5 text-white">
                  Our mission is to help you explore your passions, create new
                  connections and empower the Change Maker in you. Defining the
                  unique quest of its creators, Cluvii is a venture into the
                  unknown, a pursuit of individuality and purpose through
                  boldness, curiosity, and openness. Our belief is that boldness
                  unites. We strive to be a tool for anyone to express their
                  bold ideas, allow the most curious minds to converge together
                  to find inspiration in one another, and enable creators &
                  contributors to get rewarded.
                </p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default AboutUs;
