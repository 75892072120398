import React from "react";
import logo from "../../assets/images/logo.png";
import twitterIcon from "../../assets/images/twitter.png";
import instagramIcon from "../../assets/images/instagram.png";
import discord from "../../assets/images/discord-logo-png-7625-16x16.ico";

const Navbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand navbar-light bg-blue">
        <div className="container">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://discord.gg/zrA7jBcB"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                   <img
                    src={discord}
                    alt="instagramIcon"
                    style={{
                      height: "13px",
                      marginLeft: "-2px",
                      marginBottom: "2px",
                      brightness: "0",
                      invert: "0",
                    }}/>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.linkedin.com/company/cluvii/"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  in
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://twitter.com/cluviiapp?s=21&t=Dll3ZPSdF9iCcZEHmZoKXA">
                  <img
                    src={twitterIcon}
                    alt="twitterIcon"
                    style={{
                      height: "13px",
                      marginLeft: "-2px",
                      marginBottom: "2px",
                    }}
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://instagram.com/cluviiapp?igshid=YmMyMTA2M2Y=">
                  <img
                    src={instagramIcon}
                    alt="instagramIcon"
                    style={{
                      height: "13px",
                      marginLeft: "-2px",
                      marginBottom: "2px",
                      brightness: "0",
                      invert: "0",
                    }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="logo">
        <a href="/">
          <img src={logo} alt="logo" className="img-fluid" />
        </a>
      </div>
    </header>
  );
};

export default Navbar;
