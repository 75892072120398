import React from "react";
import { Link } from "react-router-dom";
import footerImg from "../../assets/images/logo-white.png";

const Footer = () => {
  return (
    // <!-- === footer section start === -->
    <div className="footer">
      <div className="footer-top">
        <div className="container container-xl-1170">
          <div className="row gy-lg-0 gy-5">
            <div className="col-lg-4 align-self-center text-center">
              <a href="/" style={{ textDecoration: "none" }} className="logo">
                <img src={footerImg} alt="footer" className="img-fluid" />
              </a>
            </div>
            <div className="col-lg-8">
              <div className="row gy-lg-0 gy-5">
                <div className="col-lg-4 col-sm-6">
                  <h2 className="footer-link-heading">Company</h2>
                  <ul>
                    <li>
                      <a href="/" style={{ textDecoration: "none" }}>
                        - About
                      </a>
                    </li>
                    <li>
                      <a href="/" style={{ textDecoration: "none" }}>
                        - Join Our Team
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <h2 className="footer-link-heading">Resources</h2>
                  <ul>
                    <li>
                      <a href="/" style={{ textDecoration: "none" }}>
                        - Contact
                      </a>
                    </li>
                    <li>
                      <a href="/" style={{ textDecoration: "none" }}>
                        - Guidelines
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <h2 className="footer-link-heading">Legal</h2>
                  <ul>
                    <li>
                      <Link
                        to="/home/termsofService"
                        style={{ textDecoration: "none" }}
                      >
                        - Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home/privacyPolicy"
                        style={{ textDecoration: "none" }}
                      >
                        - Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home/cookiesPolicy"
                        style={{ textDecoration: "none" }}
                      >
                        - Cookies Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-copyright" style={{ marginBottom: "0" }}>
          ©2022 <strong>Cluvii</strong> All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
