import React from "react";

const TermsofService = () => {
  return (
    // <!-- === tos section start === -->
    <section className="tos">
      <div className="container">
        <div className="tos-card shadow">
          <h3 className="agreement-title heading-3">Agreement</h3>
          <h1 className="main-title">Terms of Service</h1>
          <ol className="tos-content">
            <li className="text-preline">
              <h4 className="tos-title">Introduction</h4>
              <p>
                The Cluvii website located on www.cluvii.com (“Site”) and the
                Cluvii Mobile Applications (“App”) available on Apple App Store
                (IOS) and Google Play Store (Android), are owned and operated by
                Cluvii LLC from the United States. Throughout the Site, the
                terms “we”, “us”, “platform”, “Cluvii”, and “our” refer to
                Cluvii LLC. We offer the Cluvii Site and App, including all
                information, tools, and services available from this site to
                you, the user, conditioned upon your acceptance of all terms,
                conditions, policies, and notices stated here. By visiting our
                site and/or app, you (“User”) engage in our “Services” and agree
                to be bound by the following terms and conditions (“Terms”),
                including those additional terms and conditions and policies
                referenced herein and/or available by hyperlink. These Terms and
                Conditions apply to all users of the Site and App including
                without limitation users who are browsers, collaborators, users,
                and/ or contributors of content.
              </p>
              <p className="fw-semibold">
                PLEASE READ THESE TERMS CAREFULLY BEFORE DOWNLOADING, INSTALLING
                OR USING THE APP AND WHEN USING OUR SITE. BY DOWNLOADING,
                INSTALLING, ACCESSING, THE APP OR BY THE BROWSING THE SITE, YOU
                ACCEPT AND AGREE TO THESE TERMS WHICH BIND YOU LEGALLY. IF YOU
                DO NOT AGREE WITH THESE TERMS, OUR PRIVACY POLICY, OR ANY OTHER
                OF OUR POLICY, YOU SHOULD NOT USE THE SERVICES.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Introduction</h4>
              <ul>
                <li>
                  <p>
                    <strong>About.</strong> Cluvii is a Social platform for
                    users to post events, activities, projects in order to get
                    other users to be involved in different forms, including
                    paid users, collaborators and contributors. For more
                    information on the services we offer, please find more
                    details on www.cluvii.com or on our App.
                  </p>
                </li>
                {/* <br> */}
                <li>
                  <p>
                    <strong>Sole discretion.</strong> We reserve the right to
                    add/discontinue any service anytime at the sole discretion
                    and without notice. We also reserve the right to take
                    appropriate steps to protect Cluvii and/or its Users/Users
                    from any abuse/misuse as it deems appropriate in its sole
                    discretion.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h4 className="tos-title">App Updates and Functionality</h4>
              <p>
                From time to time, updates to the App may be made available
                through the App Store and Play Store. Depending on the update,
                and whether you utilized the App Store or Play Store to download
                and access the App, you may not be able to use the App until you
                have installed the latest version. In addition, you acknowledge
                that the App is provided over the Internet and mobile networks
                and so the quality and availability of the App may be affected
                by factors outside our reasonable control. Accordingly, we do
                not accept any responsibility for any connectivity issues that
                you may experience when using the App. In addition, you
                acknowledge and agree that you (and not us) are responsible for
                obtaining and maintaining all telecommunications, broadband and
                computer hardware, equipment and services needed to access and
                use the App, and for paying any data charges that may be charged
                to you by your network provider in connection with your use of
                the App.{" "}
              </p>
            </li>

            <li>
              <h4 className="tos-title">Store Rules</h4>
              <p>
                With respect to downloading the App, you agree to comply with
                the App Store Rules and Play Store Rules, as applicable. You
                acknowledge that the availability of the App may be dependent on
                the App Store or Play Store from which you receive the App. You
                acknowledge these Terms are between you and Cluvii and not with
                the App Store or Play Store. The App Store and Play Store are
                not responsible for the App, the content thereof, maintenance,
                support services, and warranty therefor, and addressing any
                claims relating thereto (e.g., product liability, legal
                compliance, or intellectual property infringement). You
                acknowledge that the App Store and Play Store (and their
                respective subsidiaries) are third party beneficiaries to these
                Terms and will have the right to enforce these Terms against
                you.{" "}
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title">Permitted use </h4>
              <p>
                You agree to use the Site, App and the Services only for
                purposes that are permitted by these Terms and Conditions and in
                compliance with all applicable laws, regulations, and generally
                accepted practices or guidelines in the relevant jurisdictions.
                You may only use the Services for your non-commercial,
                non-exclusive, non-assignable, non-transferable, and limited
                personal use, and no other purposes. You will not (and will not
                attempt to):
                <ol className="list-style-lower-alpha">
                  <li>Use the Services for any illegal or unlawful purpose;</li>
                  <li>
                    Access any of the Services by any means other than through
                    the interface that is provided by Cluvii;
                  </li>
                  <li>
                    Gain unauthorised access to Cluvii’s computer system or
                    engage in any activity that interferes with the performance
                    of, or impairs the functionality or security of the
                    Services, Cluvii’s networks, and computer systems;
                  </li>
                  <li>
                    Access any of the the Services through any automated means
                    or with any automated features or devices (including use of
                    scripts or web crawlers);
                  </li>
                  <li>
                    Access or collect any personally identifiable information,
                    including any names, email addresses or other such
                    information for any purpose, including, without limitation,
                    commercial purposes;
                  </li>
                  <li>
                    Reproduce, duplicate, copy, sell, trade, or resell any
                    aspect of the Services for any purpose; and
                  </li>
                  <li>
                    Reproduce, duplicate, copy, sell, trade or resell any
                    products or services bearing any trademark, service mark,
                    trade name, logo or service mark owned by Cluvii in a way
                    that is likely or intended to confuse the owner or
                    authorised user of such marks, names or logos.
                  </li>
                </ol>
              </p>
            </li>

            <li>
              <h4 className="tos-title">Interaction with other Users.</h4>
              <p>
                You are solely responsible for your communications with other
                Cluvii Users. Cluvii reserves the right, but has no obligation,
                to monitor disputes between you and other Users. Cluvii also
                reserves the right to take appropriate action against errant
                users. However, Cluvii is not obliged to share such action with
                other users, including complainants. Cluvii expressly disclaims
                any responsibility or liability for any transactions or
                interactions between the users inter-se.
              </p>
            </li>
            <li>
              <h4 className="tos-title">ayments</h4>
              <p>
                Users can use the payment processor on our platform. The
                payments are managed between the users.{" "}
              </p>
            </li>
            <li>
              <h4 className="tos-title">Limited License </h4>
              <p className="text-preline">
                We grant you a limited license to use the Services for personal
                non-commercial use only. You may not: (a) resell or make any
                commercial use of this Site and the App or any of the contents
                of our Services; (b) modify, adapt, translate, reverse engineer,
                decompile, disassemble or convert any of the contents of the
                Services not intended to be so read; (c) copy, imitate, mirror,
                reproduce, distribute, publish, download, display, perform, post
                or transmit any of the contents of the Services in any form or
                by any means; or (d) use any data mining, bots, spiders,
                automated tools or similar data gathering and extraction methods
                on the contents of the Services or to collect any information
                from the Site, App or any other user. Your use of this Site and
                the App is at your own risk. You agree that you will be
                personally responsible for your use of this Site and the App. If
                we determine, in our sole discretion, that you engaged in
                prohibited activities, were not respectful of other users, or
                otherwise violated the Terms and Conditions, we may deny you
                access to our Services on a temporary or permanent basis and any
                decision to do so is final.
              </p>
            </li>
            <li>
              <h4 className="tos-title">
                Accounts, Registrations, and Passwords
              </h4>
              <p>
                You are solely responsible for maintaining the confidentiality
                of your account and password(s) and for restricting access to
                your computer and mobile/tablet. If you open an account,
                register, or otherwise provide us with any information, you
                agree to provide us with current, complete, and accurate
                information as requested by any forms. Cluvii is not responsible
                for any errors or delays in responding to any inquiry or request
                caused by any incorrect, outdated, or incorrect information
                provided by you or any technical problems beyond the control of
                Cluvii. You may not disclose the Password to another person or
                entity or permit another entity to access the Site and Apps
                using such a Password. You must notify Cluvii immediately of any
                breach of security or unauthorised use of your account. Cluvii
                cannot be responsible and disclaims all liability in connection
                with, the use of any information that you post or display on the
                Site and App. Multiple profiles of the same person are not
                allowed on the Site and App.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Intellectual Property Rights</h4>
              <p className="text-preline">
                Your use of the Site, App and its contents grants no rights to
                you concerning any copyright, designs, and trademarks and all
                other intellectual property and material rights mentioned,
                displayed, or relating to the Content (defined below) on the
                Site and App. All Content, including third party trademarks,
                designs, and related intellectual property rights mentioned or
                displayed on this Site and the App, are protected by national
                intellectual property and other laws. Any unauthorised
                reproduction, redistribution or other use of the Content is
                prohibited and may result in civil and criminal penalties. You
                may use the Content only with our prior written and express
                authorisation. To inquire about obtaining authorisation to use
                the Content, please contact us at _____@cluvii.com In addition
                to the intellectual property rights mentioned above, "Content"
                is defined as any graphics, photographs, including all image
                rights, sounds, music, video, audio, or text on the Site and
                App.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Digital Millennium Copyright Act</h4>
              <p>
                Cluvii expects all users to respect the intellectual property
                rights of others. Cluvii may remove material that appears in its
                sole discretion to infringe upon the intellectual property
                rights of others and we will terminate the access rights of any
                repeat infringer. If you are a copyright owner or an agent
                thereof and believe that any Content infringes upon your
                copyrights, you may notify us at ____@cluvii.com. The
                notification must include the following information: physical or
                electronic signature of the owner or authorized agent of the
                owner of the allegedly infringed work; identification of the
                allegedly infringed work; identification of the material that is
                claimed to be infringing and reasonably sufficient information
                for Cluvii to locate the material; contact information of the
                notifying party, such as an address, telephone number, and
                email; a statement that the notifying party has a good faith
                belief that the use of the material in the manner complained of
                is not authorized by the owner of the allegedly infringed work,
                its agent or the law; and a statement, under penalty of perjury
                that the information in the notification is accurate and the
                notifying party is the owner or authorized agent of the
                allegedly infringed work.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Monitoring Activity</h4>
              <p>
                Cluvii has no obligation to monitor this Site and the App or any
                portion thereof. However, we reserve the right to review any
                posted content and remove, delete, redact or otherwise modify
                such content, in our sole discretion, at any time and from time
                to time, without notice or further obligation to you.{" "}
              </p>
            </li>
            <li>
              <h4 className="tos-title">Disclaimer</h4>
              <p>
                TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, CLUVII
                EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND REPRESENTATIONS,
                EXPRESS OR IMPLIED, INCLUDING ANY (A) WARRANTIES OF
                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AS TO
                THE SITE, APP AND ITS CONTENT, INCLUDING THE INFORMATION, DATA,
                SOFTWARE, OR PRODUCTS CONTAINED THEREIN, OR THE RESULTS OBTAINED
                BY THEIR USE OR AS TO THE PERFORMANCE THEREOF, (B) WARRANTIES OR
                CONDITIONS ARISING THROUGH COURSE OF DEALING, AND (C) WARRANTIES
                OR CONDITIONS OF UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. THE
                SITE AND APP AND ALL CONTENTS THEREIN AND COMPONENTS THEREOF ARE
                PROVIDED ON AN “AS IS” BASIS AND YOUR USE OF THE SITE AND APP IS
                AT YOUR OWN RISK.{" "}
              </p>
            </li>
            <li>
              <h4 className="tos-title">Limitation of Liability</h4>
              <p>
                You agree that in no event shall Cluvii be liable to you, or any
                third party, for any lost profits, incidental, consequential,
                punitive, special, or indirect damages arising out of or in
                connection with the Site and App or the Terms and Conditions,
                even if advised as to the possibility of such damages,
                regardless of whether the claim for such damages is based in
                contract, tort, strict liability or otherwise. This limitation
                on liability includes, but is not limited to, any (i) errors,
                mistakes, or inaccuracies in any Content or for any loss or
                damage of any kind incurred by you as a result of your use of or
                reliance on the Content; (ii) the transmission of any bugs,
                viruses, Trojan horses or the like which may infect your
                equipment, failure of mechanical or electronic equipment; (iii)
                unauthorised access to or use of the Site or Apps secure servers
                and/or any personal information and/or financial information
                stored therein; or (iv) theft, operator errors, strikes or other
                labor problems or any force majeure. You agree that you use the
                App and/or any Third Party Sites at your own risk. You further
                understand and agree that we are not responsible or liable for
                your illegal, unauthorized, or improper use of information
                transmitted, monitored, stored or received using the App.{" "}
              </p>
            </li>
            <li>
              <h4 className="tos-title">Indemnification</h4>
              <p>
                You agree to indemnify and hold Cluvii and its subsidiaries,
                affiliates, officers, directors, agents, and employees, harmless
                from and against any suit, action, claim, demand, penalty or
                loss, including reasonable attorneys’ fees, made by or resulting
                from any third party due to or arising out of your use of our
                Services, breach of the Terms and Conditions or the materials it
                incorporates by reference, or your violation of any law,
                regulation, order or other legal mandates, or the rights of a
                third party.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Dispute Resolution & Governing Laws</h4>
              <p className="text-preline">
                In the event of a dispute arising out of or in connection with
                these terms or any contract between you and us, then you agree
                to attempt to settle the dispute by engaging in good faith with
                us in a process of mediation before commencing arbitration or
                litigation. These Terms and Conditions shall be governed by and
                construed in accordance with the laws of California, USA, and
                you hereby submit to the exclusive jurisdiction of the
                California courts.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Children</h4>
              <p>
                We do not knowingly collect personally identifiable information
                from anyone under the age of 18. If you are a parent or guardian
                and you are aware that your Child has provided us with Personal
                Data, please contact us. If we become aware that we have
                collected Personal Data from children without verification of
                parental consent, we take steps to remove that information from
                our servers.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Privacy & Cookies</h4>
              <p>
                For more information on how we collect your information and
                cookies, please refer to our Privacy Policy and Cookie Policy.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Deleting Data And Information</h4>
              <p>
                Users on the App and Site can delete their Account. Once an
                account is deleted, we will erase ALL data of the User
                associated with their account including messages, their
                connections, and their personal information. The process can’t
                be undone once the user proceeds with the deletion of data. We
                will no longer store the data of that user after deletion.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Changes</h4>
              <p>
                We reserve the right to update and revise these Terms and
                Conditions at any time. You will know if these Terms and
                Conditions have been revised since your last visit to the
                website or the App by referring to the "Effective Date of
                Current Policy" date at the top of this page. Your use of our
                Site and App constitutes your acceptance of these Terms and
                Conditions as amended or revised by us from time to time, and
                you should, therefore, review these Terms and Conditions
                regularly.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Electronic Communications</h4>
              <p>
                When you visit the Site, use our App or send us e-mails, you are
                communicating with us electronically. In so doing, you consent
                to receive communications from us electronically. You agree that
                all agreements, notices, disclosures, and other communications
                that we provide to you electronically satisfy any legal
                requirement that such communication is in writing.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Severability</h4>
              <p>
                If any of these Terms and Conditions shall be deemed invalid,
                void, or for any reason unenforceable, that term shall be deemed
                severable and shall not affect the validity and enforceability
                of any remaining terms or conditions.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Assignment</h4>
              <p>
                We shall be permitted to assign, transfer, or subcontract our
                rights and obligations under these terms without your consent or
                any notice to you. You shall not be permitted to assign,
                transfer, or subcontract any of your rights and obligations
                under this agreement.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Force Majeure</h4>
              <p>
                Cluvii is not liable for any delays caused by circumstances
                beyond Cluvii’s control, e.g. general labour dispute, extreme
                weather, acts of war, fire, lightning, terrorist attacks,
                changed governmental orders, technical problems, defects in
                power- /tele-/computer communications or other communication and
                defects or delays in the service by sub-suppliers due to
                circumstances set forth above. Such circumstances shall result
                in relief from damages and other measures. If any such situation
                should arise, Cluvii shall inform the Customer accordingly both
                at the beginning and the end of the period for the current
                situation. If the situation has lasted for more than two months,
                both the Customer and Cluvii are entitled to terminate the
                purchase with immediate effect.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Entire Agreement</h4>
              <p>
                These Terms and Conditions set forth the entire understanding
                and agreement between you and Cluvii concerning the subject
                matter herein and supers all prior or contemporaneous
                communications and proposals, whether electronic, oral or
                written concerning the Site and App. A printed version of these
                Terms and Conditions and any notice given in electronic form
                shall be admissible in judicial or administrative proceedings
                based upon or relating to these Terms and Conditions to the same
                extent and subject to the same conditions as other business
                documents and records originally generated and maintained in
                printed form. Any rights not expressly granted herein are
                reserved. You may not assign the Terms and Conditions, or
                assign, transfer or sublicense your rights therein. A failure to
                act concerning a breach by you or others does not waive Cluvii's
                right to act concerning subsequent or similar breaches.{" "}
              </p>
            </li>
            <li>
              <h4 className="tos-title">Relationship</h4>
              <p>
                No agency, partnership, joint venture or employment relationship
                is created as a result of these Terms and neither of us has any
                authority of any kind to bind the other in any respect.
              </p>
            </li>
            <li>
              <h4 className="tos-title">Violation and Termination</h4>
              <p>
                Any conduct that in any way violates these Terms or any posted
                restrictions or guidelines may result, in our sole discretion,
                in the termination of your license and right to utilize the
                Services to access Content or for any other purpose, and/or our
                pursuit of any legal damages or remedies. If necessary, or as
                authorized under applicable law, we may cooperate with local,
                state and/or federal authorities to protect the Sites, the App,
                the Services, the Content, Cluvii, its Affiliates, Licensors,
                users, employees, agents and/or visitors; to comply with
                applicable laws; or to prevent unauthorized access or use of the
                Services or the Content. We retain the right to deny access to
                the Services, in our sole discretion, to any visitor/user for
                any reason, including for any violation of these Terms.{" "}
              </p>
            </li>
            <li clsss="text-preline">
              <h4 className="tos-title">Contact Us</h4>
              <p>
                For any questions, complaints, and queries or to report any
                violations, kindly get in touch:
              </p>

              <h4 className="tos-title">CLUVII LLC</h4>
              <p>Email: ----@cluvii.com</p>
            </li>
          </ol>

          <footer className="tos-footer d-lg-flex text-lg-left text-center">
            <a
              href="/"
              className="btn d-block btn-lg btn-outline-blue btn-border-2px"
            >
              Download PDF
            </a>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default TermsofService;
