import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="tos">
      <div className="container">
        <div className="tos-card shadow">
          <h3 className="agreement-title heading-3">Agreement</h3>
          <h1 className="main-title">Privacy Policy</h1>
          <ol className="tos-content list-style-none">
            <li>
              <p className="text-preline">
                The Cluvii Site www.cluvii.com (the “Site”) and the Cluvii
                Mobile Application (the “App”) is owned by Cluvii LLC (“we”,
                “us”, “Cluvii” and “our”) and operated from the United States.
                This privacy policy has been compiled to better serve those who
                are concerned with how their ‘Personally Identifiable
                Information’ (PII) is being used online. PII, as described in US
                privacy law and information security, is information that can be
                used on its own or with other information to identify, contact,
                or locate a single person, or to identify an individual in
                context. Please read our privacy policy carefully to get a clear
                understanding of how we collect, use, protect, or otherwise
                handle your PII. This Privacy Policy informs you of our policies
                regarding the collection, use, and disclosure of personal data
                when you use our Service and the choices you have associated
                with that data. We use your data to provide and improve the
                Service. Service means the Cluvii website and the Cluvii App
                operated by Cluvii LLC. For US residents, we adopt this notice
                to comply with the California Consumer Privacy Act of 2018
                (“CCPA”) and other US privacy laws. Any terms defined in the
                CCPA have the same meaning when used in this Policy. For users
                from Europe, see below “General Data Protection Regulation of
                2018 (GDPR), (EU) 2016/679. By using the Service, you agree to
                the collection and use of information in accordance with this
                policy. Unless otherwise defined in this Privacy Policy, terms
                used in this Privacy Policy have the same meanings as in our
                Terms and Conditions.
              </p>
            </li>
            <li>
              <h4 className="tos-title">PERSONAL INFORMATION WE COLLECT</h4>
              <p>
                <strong>
                  Personal information you provide to us (PII or Personal Data).
                </strong>{" "}
                Personal information you may provide to us through the Service
                or otherwise includes:
              </p>
              <ul className="mt-3">
                <li className="mb-3">
                  <p>
                    <strong>Personal data,</strong> C, personal information such
                    as your name, profile picture, email and mailing addresses,
                    phone number.
                  </p>
                </li>

                <li className="mb-3">
                  <p>
                    <strong>Registration data, </strong> such as information
                    that you provide to register for an account or sign up,
                    including the day and month of your birth.
                  </p>
                </li>

                <li className="mb-3">
                  <p>
                    <strong>Profile data, </strong> such as your username and
                    password that you may set to establish an online account
                    with us and your interests and preferences.{" "}
                  </p>
                </li>

                <li className="mb-3">
                  <p>
                    <strong>Marketing data, </strong> such as the email address
                    or contact details that we use to send marketing
                    communications and your preferences for receiving
                    communications about our activities, events, sweepstakes and
                    contests.
                  </p>
                </li>

                <li className="mb-3">
                  <p>
                    <strong>Purchase data, </strong> including your payment
                    history and information needed to process and fulfill your
                    service, billing address etc.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Other information, </strong> that we may collect
                    which is not specifically listed here, but which we will use
                    in accordance with this Privacy Policy or as otherwise
                    disclosed at the time of collection.
                  </p>
                </li>
              </ul>
              <p className="my-3">
                <strong>Automatic collection.</strong> We and our service
                providers may automatically log information about you, your
                computer or mobile device, and your activity occurring on or
                through the Sites, such as:
              </p>
              <ul className="mt-3">
                <li className="mb-3">
                  <p>
                    <strong>Device data,</strong> such as your computer or
                    mobile device operating system type and version number,
                    manufacturer and model, browser type, screen resolution, IP
                    address, the website you visited before browsing our site,
                    and general location information such as city, state or
                    geographic area.
                  </p>
                </li>

                <li className="mb-3">
                  <p>
                    <strong>Online activity data, </strong> such as pages or
                    screens you viewed, how long you spent on a page or screen,
                    navigation paths between pages or screens, information about
                    your activity on a page or screen, access times, and
                    duration of access.
                  </p>
                </li>
              </ul>
              <p>
                When you download the Cluvii App, we collect your device ID. As
                you access your account from the app we will collect your
                account activity
              </p>
              <p className="my-3">
                <strong>Cookies and similar technologies. </strong> Some of our
                automatic data collection is facilitated by cookies and similar
                technologies. See our Cookie Policy for more information.{" "}
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title">
                HOW WE USE YOUR PERSONAL INFORMATION
              </h4>
              <p>
                We use your personal information for the following purposes and
                as otherwise described in this Privacy Policy or at the time of
                collection:
              </p>
              <p>
                <strong>Service delivery. </strong> We may use your personal
                information to:
              </p>
              <ul>
                <li>
                  provide, operate and improve the Service, such as to enable
                  you to make purchases of our pro version or any other in-app
                  purchases;
                </li>
                <li>establish and maintain your account on the Service;</li>
                <li>
                  communicate with you about the Service, including by sending
                  you announcements, updates, security alerts, and support and
                  administrative messages;
                </li>
                <li>
                  provide customer support and maintenance for the Service;
                </li>{" "}
              </ul>
              <p>
                <strong>Direct Marketing. </strong> We may use your personal
                information to send you Cluvii-related marketing communications
                as permitted by law. You will have the ability to opt-out of our
                marketing and promotional communications as described in the
                Your Choices section below.
              </p>
              <p>
                <strong>For research and development. </strong> We may use your
                personal information for research and development purposes,
                including to analyze and improve the Service and our business.{" "}
              </p>
              <p>
                <strong>To create anonymous data. </strong> We may create
                aggregated, de-identified or other anonymous data records from
                your personal information and other individuals whose personal
                information we collect. We make personal information into
                anonymous data by excluding information (such as your name) that
                makes the data personally identifiable to you. We may use this
                anonymous data and share it with third parties for our lawful
                business purposes, including to analyze and improve the Service
                and promote our business.
              </p>
              <p>
                <strong>To comply with laws and regulations. </strong> We use
                your personal information as we believe necessary or appropriate
                to comply with applicable laws, lawful requests, and legal
                process, such as to respond to subpoenas or requests from
                government authorities.{" "}
              </p>
              <p>
                <strong>For compliance, fraud prevention and safety. </strong>{" "}
                We may use your personal information and disclose it to law
                enforcement, government authorities, and private parties as we
                believe necessary or appropriate to: (a) protect our, your or
                others’ rights, privacy, safety or property (including by making
                and defending legal claims); (b) audit our internal processes
                for compliance with legal and contractual requirements; (c)
                enforce the terms and conditions that govern the Service; and
                (d) protect, investigate and deter against fraudulent, harmful,
                unauthorized, unethical or illegal activity, including
                cyber-attacks and identity theft.
              </p>
              <p>
                <strong>With your consent. </strong> In some cases we may
                specifically ask for your consent to collect, use or share your
                personal information, such as when required by law.{" "}
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title">
                {" "}
                HOW WE SHARE YOUR PERSONAL INFORMATION{" "}
              </h4>
              <p>
                We may share your personal information with the following third
                parties and as otherwise described in this Privacy Policy or at
                the time of collection:
              </p>
              <p>
                <strong>Service providers. </strong> Companies and individuals
                that provide services on our behalf or help us operate the
                Service or our business.{" "}
              </p>
              <p>
                <strong>Third party platforms. </strong> Social media and other
                third party platforms that you connect to the Service, such as
                when you use options to access the Service by logging into a
                social media platform. Please note, we do not control the third
                party’s use of your personal information.
              </p>
              <p>
                <strong>The public. </strong> Other users of the Service and the
                public, when you disclose personal information for public use.
                For instance, you may be able review a service that you
                purchased, and we will display your name along with the content
                you submit. We do not control how other users or third parties
                use any personal information that you make available to them.
                Please be aware that any information you post publicly can be
                cached, copied, screen captured or stored elsewhere by others
                (e.g., search engines) before you have a chance to edit or
                remove it.
              </p>
              <p>
                <strong>Professional advisors. </strong> Professional advisors,
                such as lawyers, bankers, auditors and insurers, where necessary
                in the course of the professional services that they render to
                us.
              </p>
              <p>
                <strong>Authorities and others. </strong> Law enforcement,
                government authorities, and private parties, as we believe in
                good faith to be necessary or appropriate to comply with law or
                for the compliance, fraud prevention and safety purposes
                described above.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> YOUR CHOICES </h4>
              <p>
                In this section, we describe the rights and choices available to
                all users.
              </p>
              <p>
                <strong>Access or update your account information. </strong> If
                you have registered for an account with us, you may review and
                update certain personal information in your account profile by
                logging into the account.
              </p>
              <p>
                <strong>Do Not Track. </strong> Some Internet browsers may be
                configured to send “Do Not Track” signals to the online services
                that you visit. We currently do not respond to “Do Not Track” or
                similar signals. To find out more about “Do Not Track,” please
                visit http://www.allaboutdnt.com
              </p>
              <p>
                <strong>Privacy settings and location data. </strong> Users of
                our App can disable our access to their device’s precise
                geo-location in their mobile device settings.
              </p>
              <p>
                <strong>
                  Choosing not to share your personal information.{" "}
                </strong>{" "}
                If you do not provide information that we need to provide the
                Service, we may not be able to provide you with the Service or
                certain features. We will tell you what information you must
                provide to receive the Service when we request it.
              </p>
              <p>
                <strong>
                  Third-party platforms or social media networks.{" "}
                </strong>{" "}
                If you choose to create an account through or connect the
                Service with another third-party platform, you may have the
                ability to limit the information that we may obtain from the
                third-party at the time you log in to the Service using the
                third-party’s authentication service or otherwise connect your
                account. You may also be able to control your settings through
                the third-party’s platform or service after you have connected
                your accounts.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> DELETING DATA AND INFORMATION </h4>
              <p>
                Users on the App and Site can delete their Account. Once an
                account is deleted, we will erase ALL data of the User
                associated with their account including messages, their
                connections, and their personal information. The process can’t
                be undone once the user proceeds with the deletion of data.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> OTHER SITES AND SERVICES </h4>
              <p>
                The Sites may contain links to other websites and online
                services operated by third parties. These links are not an
                endorsement of, or representation that we are affiliated with,
                any third party. In addition, our content may be included on web
                pages or online services that are not associated with us. We do
                not control third party websites or online services, and we are
                not responsible for their actions. Other websites and services
                follow different rules regarding the collection, use and sharing
                of your personal information. We encourage you to read the
                privacy policies of the other websites and online services you
                use.{" "}
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> SECURITY PRACTICES </h4>
              <p>
                The security of your personal information is important to us. We
                employ a number of organizational, technical and physical
                safeguards designed to protect the personal information we
                collect. However, security risk is inherent in all internet and
                information technologies and we cannot guarantee the security of
                your personal information.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> INTERNATIONAL DATA TRANSFERS </h4>
              <p>
                Cluvii is headquartered in the United States and may have
                service providers in other countries. Your personal information
                may be transferred to the United States or other locations
                outside of your state, province, country, or other governmental
                jurisdiction where privacy laws may not be as protective as
                those in your jurisdiction.{" "}
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> CCPA – For U.S. </h4>
              <p>
                <strong>Your California privacy rights. </strong> The CCPA
                grants California residents the following rights. However, these
                rights are not absolute, and in certain cases we may decline
                your request as permitted by law.
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Information. </strong> You can request information
                    about how we have collected, used and shared and used your
                    Personal Information during the past 12 months.
                  </p>{" "}
                </li>
                <ul>
                  <li>
                    The categories of Personal Information that we have
                    collected.
                  </li>
                  <li>
                    The categories of sources from which we collected Personal
                    Information.
                  </li>
                  <li>
                    The business or commercial purpose for collecting and/or
                    selling Personal Information.
                  </li>
                  <li>
                    The categories of third parties with whom we share Personal
                    Information.
                  </li>
                  <li>
                    Whether we have disclosed your Personal Information for a
                    business purpose, and if so, the categories of Personal
                    Information received by each category of third party
                    recipient.
                  </li>
                  <li>
                    Whether we’ve sold your Personal Information, and if so, the
                    categories of Personal Information received by each category
                    of third party recipient.
                  </li>
                </ul>
                <li>
                  <p>
                    <strong>Access. </strong> You can request a copy of the
                    Personal Information that we have collected about you during
                    the past 12 months.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Deletion. </strong> You can ask us to delete the
                    Personal Information that we have collected from you.
                  </p>
                </li>
              </ul>
              <p>
                You are entitled to exercise the rights described above free
                from discrimination in the form of legally prohibited increases
                in the price or decreases in the quality of our Service. We have
                provided additional information about how you can opt-out of the
                use of your information for interest-based advertising in the
                “Your Choices” section of our Cookie Policy.{" "}
              </p>
              <p>
                <strong>How to exercise your California rights. </strong> You
                may exercise your California privacy rights described above as
                follows:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Right to information, access and deletion. </strong>{" "}
                    You can request to exercise your information, access and
                    deletion rights by emailing ---@url.com. We reserve the
                    right to confirm your California residence to process your
                    requests and will need to confirm your identity to process
                    your requests to exercise your information, access or
                    deletion rights. As part of this process, government
                    identification may be required. Consistent with California
                    law, you may designate an authorized agent to make a request
                    on your behalf. In order to designate an authorized agent to
                    make a request on your behalf, you must provide a valid
                    power of attorney, the requester’s valid government-issued
                    identification, and the authorized agent’s valid government
                    issued identification. We cannot process your request if you
                    do not provide us with sufficient detail to allow us to
                    understand and respond to it.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Request a list of third party marketers. </strong>{" "}
                    California’s “Shine the Light” law (California Civil Code §
                    1798.83) allows California residents to ask companies with
                    whom they have formed a business relationship primarily for
                    personal, family or household purposes to provide certain
                    information about the companies’ sharing of certain personal
                    information with third parties for their direct marketing
                    purposes during the preceding year (if any). You can submit
                    such a request by sending an email to --@url.com with “Shine
                    the Light” in the subject line. The request must include
                    your current name, street address, city, state, and zip code
                    and attest to the fact that you are a California resident.
                  </p>
                </li>
              </ul>
              <p>
                We cannot process your request if you do not provide us with
                sufficient detail to allow us to understand and respond to it.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title">
                {" "}
                GENERAL DATA PROTECTION REGULATION (GDPR){" "}
              </h4>
              <p>
                If you are from the European Economic Area (EEA), Cluvii legal
                basis for collecting and using the personal information
                described in this Privacy Policy depends on the Personal Data we
                collect and the specific context in which we collect it. Cluvii
                may process your Personal Data because:{" "}
              </p>
              <ul>
                <li>
                  <p>We need to perform a contract with you</p>
                </li>
                <li>
                  <p>You have given us permission to do so</p>
                </li>
                <li>
                  <p>
                    The processing is in our legitimate interests and it’s not
                    overridden by your rights
                  </p>
                </li>
                <li>
                  <p>For payment processing purposes</p>
                </li>
                <li>
                  <p>To comply with the law</p>
                </li>
              </ul>
              <p>
                <strong>Retention of Data</strong>
              </p>
              <p>
                Cluvii will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                Cluvii will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of our Service, or we
                are legally obligated to retain this data for longer time
                periods.
              </p>
              <p>
                <strong>Transfer of Data</strong>
              </p>
              <p>
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from your
                jurisdiction. Your consent to this Privacy Policy followed by
                your submission of such information represents your agreement to
                that transfer. Cluvii will take all steps reasonably necessary
                to ensure that your data is treated securely and in accordance
                with this Privacy Policy and no transfer of your Personal Data
                will take place to an organization or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </p>
              <p>
                <strong>Disclosure of Data</strong>
              </p>
              <p>
                Legal Requirements Cluvii may disclose your Personal Data in the
                good faith belief that such action is necessary to:
              </p>
              <ul>
                <li>
                  <p> To comply with a legal obligation </p>
                </li>
                <li>
                  <p>
                    {" "}
                    To protect and defend the rights or property of Cluvii{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    To prevent or investigate possible wrongdoing in connection
                    with the Service{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    To protect the personal safety of users of the Service or
                    the public{" "}
                  </p>
                </li>
                <li>
                  <p> To protect against legal liability </p>
                </li>
              </ul>
              <p>
                {" "}
                <strong>Security of Data</strong>{" "}
              </p>
              <p>
                The security of your data is important to us, but remember that
                no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <p>
                <strong>Your rights under the GDPR</strong>
              </p>
              <p>
                If you are a resident of the European Economic Area (EEA), you
                have certain data protection rights. Cluvii aims to take
                reasonable steps to allow you to correct, amend, delete, or
                limit the use of your Personal Data. If you wish to be informed
                what Personal Data we hold about you and if you want it to be
                removed from our systems, please contact us. In certain
                circumstances, you have the following data protection rights:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      {" "}
                      The right to access, update or to delete the information
                      we have on you.{" "}
                    </strong>{" "}
                    Whenever made possible, you can access, update or request
                    deletion of your Personal Data directly within your account
                    settings section. If you are unable to perform these actions
                    yourself, please contact us to assist you.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> The right of rectification. </strong> You have the
                    right to have your information rectified if that information
                    is inaccurate or incomplete.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> The right to object. </strong> You have the right
                    to object to our processing of your Personal Data.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> The right of restriction. </strong> You have the
                    right to request that we restrict the processing of your
                    personal information.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> The right to data portability. </strong> You have
                    the right to be provided with a copy of the information we
                    have on you in a structured, machine-readable and commonly
                    used format.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> The right to withdraw consent. </strong> You also
                    have the right to withdraw your consent at any time where
                    Cluvii relied on your consent to process your personal
                    information.
                  </p>
                </li>
              </ul>
              <p>
                Please note that we may ask you to verify your identity before
                responding to such requests. You have the right to complain to a
                Data Protection Authority about our collection and use of your
                Personal Data. For more information, please contact your local
                data protection authority in the European Economic Area (EEA).
              </p>
              <p>
                <strong>Service Providers</strong>
              </p>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (“Service Providers”), to provide the
                Service on our behalf, to perform Service-related services or to
                assist us in analyzing how our Service is used. These third
                parties have access to your Personal Data only to perform these
                tasks on our behalf and are obligated not to disclose or use it
                for any other purpose.
              </p>
              <p>
                <strong>ANALYTICS</strong>{" "}
              </p>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Google Analytics</strong>{" "}
                  </p>
                </li>
              </ul>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network. For more information on the privacy
                practices of Google, please visit the Google Privacy & Terms web
                page:{" "}
                <a
                  target="_blank" rel="noreferrer"
                  href="http://www.google.com/intl/en/policies/privacy/"
                >
                  http://www.google.com/intl/en/policies/privacy/
                </a>
                Advertising We may use third-party Service Providers to show
                advertisements to you to help support and maintain our Service.
              </p>
              <ul>
                <li>
                  <p>
                    <strong>AdMob by Google</strong>
                  </p>
                </li>
              </ul>
              <p>
                AdMob by Google is provided by Google Inc. You can opt-out from
                AdMob by Google service by following the instructions described
                by Google:{" "}
                <a
                  target="_blank" rel="noreferrer"
                  href=" https://support.google.com/ads/answer/2662922?hl=en"
                >
                  {" "}
                  https://support.google.com/ads/answer/2662922?hl=en
                </a>
                For more information on how Google uses the collected
                information, please visit the “How Google uses data when you use
                our partners’ sites or app” page:{" "}
                <a
                  target="_blank" rel="noreferrer"
                  href="http://www.google.com/policies/privacy/partners/"
                >
                  http://www.google.com/policies/privacy/partners/
                </a>{" "}
                or visit the Privacy Policy of Google:{" "}
                <a
                  target="_blank" rel="noreferrer"
                  href="http://www.google.com/policies/privacy/"
                >
                  http://www.google.com/policies/privacy/
                </a>
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> PAYMENTS </h4>
              <p>
                We may provide in-app purchase and/or paid services within the
                Service. In that case, we use third-party services for payment
                processing (e.g. payment processors). We will not store or
                collect your payment card details. That information is provided
                directly to our third-party payment processors whose use of your
                personal information is governed by their Privacy Policy. These
                payment processors adhere to the standards set by PCI-DSS as
                managed by the PCI Security Standards Council, which is a joint
                effort of brands like Visa, Mastercard, American Express and
                Discover. PCI-DSS requirements help ensure the secure handling
                of payment information. The payment processors we work with are:
              </p>
              <ul>
                <li>Apple Store In-App Payments</li>{" "}
              </ul>
              <p>
                Their Privacy Policy can be viewed at{" "}
                <a
                  target="_blank" rel="noreferrer"
                  href="https://www.apple.com/legal/privacy/en-ww/"
                >
                  {" "}
                  https://www.apple.com/legal/privacy/en-ww/
                </a>
              </p>
              <ul>
                <li>Google Play In-App Payments</li>{" "}
              </ul>
              <p>
                Their Privacy Policy can be viewed at{" "}
                <a
                  target="_blank" rel="noreferrer"
                  href="https://www.google.com/policies/privacy/"
                >
                  {" "}
                  https://www.google.com/policies/privacy/
                </a>
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> CHILDREN’S PRIVACY </h4>
              <p>
                The Children's Online Privacy Protection Act (COPPA) protects
                the personal information of children under the age of 13.
                Consistent with the Children's Online Privacy Protection Act of
                1998, we will never knowingly request personally identifiable
                information from anyone under the age of 13 without prior
                verifiable parental consent. Parents can revoke their consent
                and ask that information about their children be deleted from
                our site by contacting our offices by sending an email provided
                below.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> CHANGES TO THIS PRIVACY POLICY </h4>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. We will let you know via email and/or a prominent
                notice on our Service, prior to the change becoming effective
                and update the “effective date” at the top of this Privacy
                Policy. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
            </li>

            <li className="text-preline">
              <h4 className="tos-title"> CONTACT US </h4>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us: ----@cluvii.com
              </p>
            </li>
          </ol>

          <footer className="tos-footer d-lg-flex text-lg-left text-center">
            <a
              target="_blank"
              href="/"
              className="btn d-block btn-lg btn-outline-blue btn-border-2px"
            >
              Download PDF
            </a>
          </footer>
        </div>
      </div>
    </section>
    //   <!-- ===//end of tos section === -->/
  );
};

export default PrivacyPolicy;
